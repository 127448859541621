import { Alert, Autocomplete, Box, Button, Paper, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import Forms_BG from "../images/Forms_BG3.png";
import Forms_BG2 from "../images/Forms_BG1.png";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export default function EmailForm(){
    const listOfJobs = ['Account Executive',
'Account Manager',
'Accountant',
'Advertising Manager',
'Architect',
'Auditor',
'Back End Developer',
'Brand Manager',
'Budget Analyst',
'Business Development Manager',
'Cloud Engineer - Azure, GCP',
'Content Strategist',
'Credit Analyst',
'Cybersecurity Specialist',
'Data Scientist',
'DevOps Engineer',
'Digital Marketing Specialist',
'Finance Manager',
'Financial Analyst',
'Front End Developer',
'Full Stack Developer',
'IT Specialist',
'Inside Sales Representative',
'Investment Analyst',
'Journalist',
'Marketing Analyst',
'Marketing Coordinator',
'Network Administrator',
'Product Marketing Manager',
'Public Relations Specialist',
'Regional Sales Manager',
'Risk Manager',
'SEO Specialist',
'Sales Analyst',
'Sales Director',
'Sales Manager',
'Sales Operations Specialist',
'Sales Representative',
'Social Media Manager',
'Software Engineer',
'Solutions Architect',
'System Analyst',
'Tax Advisor',
'Test Engineer',
'Treasury Analyst',
'UI/UX Specialist',
'Writer'];
    const experience = ['Intern', 'Entry-Level','Associate','Senior','Director','Executive'];
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [fullName,setFullName] = useState('');
    const [email,setEmail] = useState('');
    const [jobRole,setJobRole] = useState(null);
    const [contactNumber,setContactNumber] = useState('');
    const [level,setLevel] = useState(null);
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    const [emailError, setEmailError] = useState('');
    const [emptyError, setEmptyError] = useState('');
    const data ={
      full_name:"USER",
      email:"",
      contact_number:"",
      job_role:"",
      level:""
    };

    const nameHandler = (event) => {
        setFullName(event.target.value);
    }
    const emailHandler = (event) => {
        setEmailError('');
        setEmail(event.target.value);
    }
    const contactNumberHandler = (event) => {
        setContactNumber(event.target.value);
    }
    const jobRoleHandler = (event, newValue) => {
      setEmptyError('');
        setJobRole(newValue);
    }
    const levelHandler = (event,newValue) => {
      setEmptyError('');
        setLevel(newValue);
    }

    const enrollMmjUser = async (body) => {
      console.log(body);
      try {
      const response = await fetch("/api/mmj/subscribe", {
        method: "POST",
        credentials : 'include',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      })
      const data = await response.json()
      console.log("User Added!");
    } catch (error) {
       console.error(error); 
    }
    };

    const handleClickOpen = (event) => {
      event.preventDefault();
        if(!emailRegex.test(email))
        { setEmailError('Please enter a valid Email!'); 
          return
        }
        if(jobRole == null || level === null)
        {
          setEmptyError('Please Fill all the fields!');
          return
        }
        data.full_name = fullName;
        data.email = email;
        data.contact_number = contactNumber;
        data.job_role = jobRole;
        data.level = level;
        
        enrollMmjUser(data);
        setOpen(true);
    };

    const handleClose = () => {
        setFullName('');
        setEmail('');
        setContactNumber('');
        setJobRole(null);
        setLevel(null);
        setOpen(false);
    };

    const [visible1, setVisible1] = useState(false);
    const typographyRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (enteries) => {
                const [entry] = enteries;
                if(entry.isIntersecting){
                    setVisible1(true);
                    observer.disconnect();

                }
            },{threshold:0.2}
        );
        if(typographyRef.current){
            observer.observe(typographyRef.current);
        }
        return ()=> {
            if(typographyRef.current)
            observer.unobserve(typographyRef.current);
        };
     },[]);

    return (
        <Fragment>
            <Box ref={typographyRef} sx={{backgroundImage:`url(${Forms_BG})`, height: "auto", width: "98.7vw", position : "relative", display : "flex",flexDirection:'column', backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat', transform: visible1 ? 'translateX(0)' : 'translateX(-50px)', opacity: visible1 ? 1 : 0, transition : 'transform 0.6s ease-out, opacity 0.6s ease-out'}} id='enroll'>
            <Box sx={{ width: "98.7vw", position : "relative", display : "flex", justifyContent: 'center',}}>
            <Typography variant="h2" style={{fontFamily : 'SUSE', fontSize:{xs: '2.5rem', sm:'3.5rem', md:'4.5rem'},color : 'black', letterSpacing:5}} pt={2}>Lets Get You Enrolled!!!</Typography>
            </Box>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem', // Ensure space on smaller screens
                }}
            >

            <Paper elevation={3} component={'form'} onSubmit={handleClickOpen} sx={{
            padding: {
            xs: '1rem', // Mobile view padding
            md: '2rem', // Desktop view padding
            },
            width: {
            xs: '90%', // Mobile view width
            sm: '400px', // Small screens and up
            },
            backgroundColor: 'rgba(79, 186, 199, 0.2)', // Slight transparent black background
            color: 'white',
            borderRadius: '8px',
            }}>
                <Box sx={{ marginBottom: '0.5rem' }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
                fontFamily:'SUSE',
                color:'black',
              fontSize: {
                xs: '1rem', // Mobile font size
                md: '1.25rem', // Desktop font size
              }, letterSpacing:3
            }}
          >
            Full Name : 
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="David Joe"
            input
            slotProps={{
                input:{
              sx: {
                backgroundColor: 'white',
                borderRadius: '10px',
                fontFamily:'SUSE'
              },}, 
              htmlInput:{
                maxLength:50,minLength:1
              }
            }}
            required value={fullName}
            onChange={nameHandler}
          />
        </Box>
        <Box sx={{ marginBottom: '0.5rem' }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: {
                xs: '1rem',
                md: '1.25rem',
              },fontFamily: 'SUSE',color:'black',letterSpacing:3
            }}
          >
            Email :
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="matchmejobs@gmail.com"
            required value={email}
            onChange={emailHandler}
            slotProps={{
                input:{
              sx: {
                backgroundColor: 'white',
                borderRadius: '10px',
                fontFamily:'SUSE'
              },}, 
              htmlInput:{
                maxLength:60,
                minLength:3
              }
            }}
          />
        </Box>
        <Box sx={{ marginBottom: '0.5rem' }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: {
                xs: '1rem',
                md: '1.25rem',
              },fontFamily : 'SUSE',color:'black',letterSpacing:3
            }}
          >
            Contact Number : 
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="8672908097"
            required value={contactNumber}
            onChange={contactNumberHandler}
            slotProps={{
                input :{
              sx: {
                backgroundColor: 'white',
                borderRadius: '10px',
                fontFamily:'SUSE'
              },},
              htmlInput:{
                maxLength:10,
                minLength:10
              }
            }}
          />
        </Box>
        <Box sx={{ marginBottom: '0.5rem' }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: {
                xs: '1rem',
                md: '1.25rem',
              },fontFamily:'SUSE',color:'black',letterSpacing:3
            }}
          >
            Select Your Job Role :
          </Typography>
          <Autocomplete
      options={listOfJobs} // Your list of options
      fullWidth
      required
      value={jobRole}
      onChange={jobRoleHandler}
      sx={{borderRadius:'10px'}}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Software Engineer"
          variant="outlined"
          required
          sx={{
            backgroundColor: 'white',
            borderRadius: '10px',fontFamily:'SUSE'
          }}
        />
      )}
    />
        </Box>
        <Box sx={{ marginBottom: '0.5rem' }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: {
                xs: '1rem',
                md: '1.25rem',
              },fontFamily:'SUSE',color:'black',letterSpacing:3
            }}
          >
            Select Your Experience :
          </Typography>
          <Autocomplete
      options={experience} // Your list of options
      fullWidth required value={level}
      onChange={levelHandler}
      sx={{borderRadius:'10px'}}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Fresher"
          variant="outlined" required
          sx={{
            backgroundColor: 'white',
            borderRadius: '10px',fontFamily:'SUSE'
          }}
        />
      )}
    /></Box>
        <Box sx={{ marginBottom: '0.5rem', display:'flex',justifyContent: 'center',alignItems:'center' }}>
        <Button variant="contained" size="small" sx={{width : "200px", borderRadius : "60px", fontFamily:'SUSE', letterSpacing:5, backgroundColor:'white',color:'black', ':hover' : {backgroundColor:'#4FBAC7', color:'white'}, boxShadow:'20px 20px 30px rgba(0,0,0,0.3)', marginTop:'10px',  }} type="submit">Loop Me In!</Button>
        </Box>
        <Box sx={{ marginBottom: '0.5rem' }}>
        { emailError &&    
              <Alert variant="filled" severity="error">
                {emailError}
              </Alert>}
              { emptyError &&    
              <Alert variant="filled" severity="error">
                {emptyError}
              </Alert>}
        </Box>
      </Paper>
            </Box>
            </Box>

            <Dialog
        fullScreen={fullScreen}
        maxWidth='sm'
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{'& .MuiPaper-root':{backgroundImage:`url(${Forms_BG2})`,backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}}}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Thank You for Subscribing!"}
        </DialogTitle>
        <DialogContent sx={{flexDirection:'column'}}>
          <Typography>
          We're thrilled to have you on board! You've taken the first step towards simplifying your job search process. Sit back, relax, and leave the searching to us. Our tailored job leads will land directly in your inbox, ensuring you never miss an opportunity that matches your skills and preferences.
        </Typography>
        <Typography>
        Say goodbye to hours of scrolling and hunting for the right position — we're here to do the heavy lifting so you can focus on what matters most.
        </Typography>
        <Typography>
        Keep an eye on your inbox. Great opportunities are on their way!
        </Typography>
          
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} sx={{bgcolor:'black',color:'white'}}>
            Okay!
          </Button>
        </DialogActions>
      </Dialog>
        </Fragment>
    )
}
