import { Fragment, useEffect, useState } from "react"
import Box from '@mui/material/Box';
import { Button,Grid2, Typography} from "@mui/material";
import { useScrollTrigger } from '@mui/material';
import WhyUsPage from "./whyUsPage";
import HomeBG from "../images/Home_BG.png";
import OurServices from "./ourServices";
import EmailForm from "./EmailForm";
import Footer from "./Footer";
import Typewriter from 'typewriter-effect';

export default function Homepage(){
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 300, // Adjust the threshold as needed
    });

    useEffect(() => {
        if (trigger) {
          setVisible(true);
        }
      }, [trigger]);
    
    useEffect(() => {
       setTimeout(() => { 
        setVisible1(true);
       },100);
    },[]);
    
  

    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
    setIsLoaded(true);
    }, []);
    
    return(
        <Fragment>
            {/* <MyAppBar /> */}
            <Box sx={{backgroundImage:`url(${HomeBG})`, height: "98vh", width: "98.7vw", position : "relative", display : "flex", justifyContent: 'center', alignItems : 'center',backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}} id='home'> 
            <Box sx={{position : "absolute",top:0,left:0,right:0,bottom:0, backgroundColor : 'rgba(79,186,199,.4)', backdropFilter: 'blur(2px)', zIndex: 1}}/> 

            <Grid2 container direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{position : 'relative', zIndex: 2, textAlign : 'center', width : '100%', }}>

                <Grid2 item xs={12} > 
               <Typography variant="h1" style={{fontFamily : 'SUSE', fontSize:{xs: '2.5rem', sm:'3.5rem', md:'4.5rem'},color : 'white', letterSpacing : 15, transform: visible1 ? 'translateY(0)' : 'translateY(-50px)', opacity: visible1 ? 1 : 0, transition : 'transform 0.6s ease-out, opacity 0.6s ease-out'}}>
                MATCH ME JOBS
               </Typography>
               </Grid2>
               <Grid2 item xs={12}>
               <Typography variant="h4" style={{fontFamily : 'SUSE', fontSize:{xs: '1.5rem', sm:'2rem', md:'2.5rem'},color : 'black', letterSpacing : 10}} pl={3}>
                {/* SPEED MEETS OPPURTUNITY */}
                <Typewriter
                    onInit={(typewriter) => {
                    typewriter.typeString('SPEED MEETS POTENTIAL')
                    .pauseFor(1500)
                    .deleteChars(9)
                    .typeString('ADVANTAGE')
                    .pauseFor(1500)
                    .deleteChars(9)
                    .typeString('OPPURTUNITY')
                    .start();
  }}
/>
               </Typography>
               </Grid2>
               <Grid2 item xs={12} sx={{transform: visible1 ? 'translateY(0)' : 'translateY(-50px)', opacity: visible1 ? 1 : 0, transition : 'transform 0.6s ease-out, opacity 0.6s ease-out'}}>
               <Button variant="contained" size="large" sx={{width : "300px", borderRadius : "60px", fontFamily:'SUSE', letterSpacing:5, backgroundColor:'white',color:'black', ':hover' : {backgroundColor:'#4FBAC7', color:'white'}, boxShadow:'20px 20px 30px rgba(0,0,0,0.3)'}}
               onClick={() => 
                { document.getElementById('enroll').scrollIntoView({behavior:'smooth'});}}>SPeed ME UP!</Button>
               </Grid2>
               </Grid2>
            </Box>
            <WhyUsPage />
            <OurServices/>
            <EmailForm />
            <Footer />
        </Fragment>
    )
}