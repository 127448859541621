import { Box, Button, Grid2, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import ErrorGif1 from "../images/lost1.gif";
import ErrorGif2 from "../images/lost2.gif";
import ErrorGif3 from "../images/lost3.gif";
import ErrorGif4 from "../images/lost4.gif";
import NotFoundImg from "../images/NotFound.png";
import { useNavigate } from "react-router-dom";

export default function NotFound(){
    const navigate = useNavigate();
    const gifs = [ErrorGif1, ErrorGif2, ErrorGif3, ErrorGif4];
    const [randomGif, setRandomGif] = useState('');
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * gifs.length);
        setRandomGif(gifs[randomIndex]);
    },[gifs]);
    return(
        <Fragment>
             <Box sx={{backgroundImage:`url(${NotFoundImg})`,height: "120vh", width: "98.7VW", position : "relative", display : "flex", justifyContent: 'center', alignItems : 'center',backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat', opacity:''}}>
             {/* <Box sx={{ width: "98.7vw", position : "relative", display : "flex", justifyContent: 'center',}} pt={3}> */}
             <Grid2 container direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{position : 'relative', zIndex: 2, textAlign : 'center', width : '100%', }}>
             <Grid2 item xs={12} > 
                <Typography variant="h1" sx={{fontFamily:"SUSE", letterSpacing:10, color:'white'}}>MATCH ME JOBS</Typography>
             </Grid2>
             <Grid2 item xs={12} > 
                <Typography variant="h2" sx={{fontFamily:"SUSE",letterSpacing:5, color:'white'}}>404 - PAGE NOT FOUND</Typography>
             </Grid2>
             <Grid2 item xs={12} > 
             <img src={randomGif} alt="A Gif" style={{maxWidth:'80%', height:'auto'}} />
             </Grid2>
             <Grid2 item xs={12} > 
             <Button variant="contained" size="large" sx={{width : "auto", borderRadius : "60px", fontFamily:'SUSE', letterSpacing:5, backgroundColor:'white',color:'black', ':hover' : {backgroundColor:'black', color:'white'}, boxShadow:'20px 20px 30px rgba(0,0,0,0.3)', marginTop:'10px',  }} onClick={() => navigate('/')}>Take me to MATCH ME JOBS</Button>
             </Grid2>
             </Grid2>
                {/* </Box> */}
                
             </Box>
        </Fragment>
    )
}