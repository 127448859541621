import { Box, Divider, Grid2, Typography, useMediaQuery } from "@mui/material";
import { Fragment } from "react";
import { useTheme } from "@mui/system";
import Facebook from '../images/Facebook.png';
import Instagram from '../images/Instagram.png';
import Linkedin from '../images/Linkedin.png';
import Twitter from '../images/Twitter.png';

export default function Footer(){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Fragment>
            <Box sx={{backgroundColor:'#36454F', height: "auto", width: "98.7vw", position : "relative", display : "flex", justifyContent: 'flex-start',}} id='about-us' >
            {/* <Box component='img' src={MMJ_Logo} alt="Mail Icon" sx={{width:150,height:100}} pt={2}/> */}
            <Grid2 container spacing={2} sx={{ justifyContent:{xs:'center'}}} pt={2}>
                    <Grid2 item xs={12} md={5} sx={{display:'flex',alignItems:'start',height:'auto',width:{xs:'auto',md:'550px'}, flexDirection:'column',}} mb={2}>
                        {/* <Box component='img' src={MMJ_Logo} alt="Mail Icon" sx={{width:160,height:100}} pt={2} pl={5}/> */}
                        <Typography variant="h5" style={{fontFamily:'SUSE',xs:'1rem', md:'1rem', color:'rgb(15,192,236)', wordSpacing:2}} mt={2} ml={2.5} >Who We Are :</Typography>
                        <Typography variant="body2" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem', color:'#3497A3', wordSpacing:2, }} mt={1} ml={2.5} >
                        We are a dedicated team driven by the goal of simplifying time-consuming tasks such as sending resumes and filling out forms. Our mission is to save you time and effort by offering streamlined, efficient solutions that enhance your productivity. With a focus on user-friendly design and seamless functionality, we strive to eliminate the hassle from everyday processes, allowing you to focus on what truly matters. Let us handle the tedious work, so you can achieve more faster. 
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} md={2} sx={{display:'flex', justifyContent:'center', alignItems:'center'}} pb={isMobile ? 0 : 2}>
                    <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem sx={{ height: isMobile ? '80vh' : '100%', width: isMobile ? '80vw' : '1px'}} />
                    </Grid2>
                    <Grid2 item xs={12} md={5} sx={{display:'flex',alignItems:'flex-start',height:{xs:'auto', md:'auto'},width:'auto', flexDirection:'column',}} >
                        <Typography variant="h5" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem', color:'rgb(15,192,236)', wordSpacing:4}} mt={2} ml={2.5} >
                        Connect with us at : 
                        </Typography>
                        <Box sx={{width:'auto'}}>
                        <Box component='img' src={Facebook} alt="Mail Icon" sx={{width:30,height:30}} pt={2} pl={5}/>
                        <Box component='img' src={Instagram} alt="Mail Icon" sx={{width:30,height:30}} pt={2} pl={5}/>
                        <Box component='img' src={Twitter} alt="Mail Icon" sx={{width:30,height:30}} pt={2} pl={5}/>
                        <Box component='img' src={Linkedin} alt="Mail Icon" sx={{width:30,height:30}} pt={2} pl={5}/>
                        </Box>
                        <Typography variant="h5" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem', color:'rgb(15,192,236)', wordSpacing:4}} mt={2} ml={2.5} >
                        Or Write a mail to us at : 
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem', color:'rgb(187,31,208)',fontStyle:"italic", wordSpacing:4,backgroundcolor: "primary",
    backgroundImage: `linear-gradient(45deg, #fd746c, #ff9068)`,
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"}}  ml={2.5} >
                        matchmejobs@gmail.com 
                        </Typography>
                    </Grid2>
                    </Grid2>
            </Box>
        </Fragment>
    )
}