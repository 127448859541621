import { Box, Grid2, Typography} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";

export default function WhyUsPage(){
    const [visible1, setVisible1] = useState(false);
    const typographyRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (enteries) => {
                const [entry] = enteries;
                if(entry.isIntersecting){
                    setVisible1(true);
                    observer.disconnect();

                }
            },{threshold:0.05}
        );
        if(typographyRef.current){
            observer.observe(typographyRef.current);
        }
        return ()=> {
            if(typographyRef.current)
            observer.unobserve(typographyRef.current);
        };
     },[]);
    return(
        <Fragment >
            <Box ref={typographyRef} sx={{bgcolor : '#FAF9F6',transform: visible1 ? 'translateX(0)' : 'translateX(-50px)', opacity: visible1 ? 1 : 0, transition : 'transform 0.6s ease-out, opacity 0.6s ease-out'}} pb={2} id='benefits'>
            <Box ref={typographyRef} sx={{ width: "98.7vw", position : "relative", display : "flex", justifyContent: 'center',}}>
            <Typography variant="h2" style={{fontFamily : 'SUSE', fontSize:{xs: '2.5rem', sm:'3.5rem', md:'4.5rem'},color : 'black',}} pt={2}>Why To Choose <span style={{color: '#3497A3'}}>Match ME Jobs</span>?</Typography>
            </Box>

                <Grid2 container spacing={2} sx={{ justifyContent:{xs:'center'},}} pt={2}>
                    <Grid2 item xs={12} md={6} sx={{borderRadius:'20px', boxShadow:'5px 5px 15px rgba(0,0,0,0.2)',transition:'box-shadow 0.3s ease-in-out',display:'flex',alignItems:'flex-start',height:{xs:'auto', md:'200px'},width:'390px', flexDirection:'column', ':hover' :{boxShadow:'10px 10px 20px rgba(0,0,0,0.3)'}}} direction='row'>
                        <Typography variant="h4" style={{fontFamily:'SUSE', fontSize: {xs:'2rem', md:'3rem'}, wordSpacing:5, color:'#3497A3'}} mt={2} ml={2}>
                            Curated Job Leads
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem', color:'#4FBAC7'}} mt={2} ml={2.5} >
                            We bring you only the best-matched job openings from Linkedin & Naukri.com, saving you hours of scrolling.
                        </Typography>
                    </Grid2>
                    <Grid2 item xs={12} md={6} sx={{border: '1px solid #4FBAC7',borderRadius:'20px', boxShadow:'5px 5px 15px rgba(0,0,0,0.2)',transition:'box-shadow 0.3s ease-in-out',display:'flex',alignItems:'flex-start',height:{xs:'auto', md:'200px'},width:'390px', flexDirection:'column', ':hover' :{boxShadow:'10px 10px 20px rgba(0,0,0,0.3)'}}} direction='row'>
                        <Typography variant="h4" style={{fontFamily:'SUSE', fontSize: {xs:'2rem', md:'3rem'}, wordSpacing:5, color:'#3497A3'}} mt={2} ml={2}>
                            Your Career, Simplified
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem',color:'#4FBAC7'}} mt={2} ml={2.5}>
                            Designed for job seekers who value time, clarity, and precision in their search for Success!
                        </Typography>
                    </Grid2>
                    <Grid2 item xs={12} md={6} sx={{border: '1px solid #4FBAC7',borderRadius:'20px', boxShadow:'5px 5px 15px rgba(0,0,0,0.2)',transition:'box-shadow 0.3s ease-in-out',display:'flex',alignItems:'flex-start',height:{xs:'auto', md:'200px'},width:'390px', flexDirection:'column', ':hover' :{boxShadow:'10px 10px 20px rgba(0,0,0,0.3)'}}} direction='row'>
                        <Typography variant="h4" style={{fontFamily:'SUSE', fontSize: {xs:'2rem', md:'3rem',}, wordSpacing:5, color:'#3497A3'}} mt={2} ml={2}>
                            Real-Time Updates
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'1.50rem',color:'#4FBAC7'}} mt={2} ml={2.5}>
                            Be the first to know when new oppurtunities pop up - no more missed chances!
                        </Typography>
                    </Grid2>
                    <Grid2 item xs={12} md={6} sx={{borderRadius:'20px', boxShadow:'5px 5px 15px rgba(0,0,0,0.2)',transition:'box-shadow 0.3s ease-in-out',display:'flex',alignItems:'flex-start',height:{xs:'auto', md:'200px'},width:'390px', flexDirection:'column', ':hover' :{boxShadow:'10px 10px 20px rgba(0,0,0,0.3)'}}} direction='row'>
                        <Typography variant="h4" style={{fontFamily:'SUSE', fontSize: {xs:'2rem', md:'3rem'},wordSpacing:5, color:'#3497A3'}} mt={2} ml={2}>
                            No More Overwhelm
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'1.50rem',color:'#4FBAC7'}} mt={2} ml={2}>
                            Say goodbye to job board clutter. We cut through the noise to deliver only relevant leads.
                        </Typography>
                    </Grid2>
                </Grid2>
                </Box>
        </Fragment>
    )
};