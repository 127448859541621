import { Box, Button, Grid2, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import EnvIcon from "../images/envelope_1.png"
import matchIcon from "../images/match.png";

export default function OurServices(){
    const [visible1, setVisible1] = useState(false);
    const typographyRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (enteries) => {
                const [entry] = enteries;
                if(entry.isIntersecting){
                    setVisible1(true);
                    observer.disconnect();

                }
            },{threshold:0.2}
        );
        if(typographyRef.current){
            observer.observe(typographyRef.current);
        }
        return ()=> {
            if(typographyRef.current)
            observer.unobserve(typographyRef.current);
        };
     },[]);
    return(
        <Fragment>
            <Box sx={{bgcolor : '#192024', width:'98.7vw', height:'auto',}} pb={2} id='services'>
            <Box ref={typographyRef} sx={{ width: "98.7vw", position : "relative", display : "flex", justifyContent: 'center', transform: visible1 ? 'translateY(0)' : 'translateY(-50px)', opacity: visible1 ? 1 : 0, transition : 'transform 0.6s ease-out, opacity 0.6s ease-out'}} pt={3}>
            <Typography variant="h2" style={{fontFamily : 'SUSE', fontSize:{xs: '2.5rem', sm:'3.5rem', md:'4.5rem'},color : 'white', letterSpacing:5}} pt={2}>What We Bring To<span style={{color: '#7DF9FF'}}> You</span></Typography>
            </Box>
            <Grid2 container spacing={2} sx={{ justifyContent:{xs:'center'}}} pt={2}>
                    <Grid2 item xs={12} md={6} sx={{border: '1px solid #4FBAC7',borderRadius:'20px', boxShadow:'5px 5px 15px rgba(79,186,199,0.3)',transition:'box-shadow 0.3s ease-in-out',display:'flex',alignItems:'center',height:{xs:'auto', md:'auto'},width:'410px', flexDirection:'column', ':hover' :{boxShadow:'10px 10px 20px rgba(79,186,199,0.4)'}}} direction='row' pb={2}>
                        <Typography variant="h4" style={{fontFamily:'SUSE', fontSize: {xs:'2rem', md:'3rem'}, wordSpacing:5, color:'white', letterSpacing: 4}} mt={2} ml={2} >
                            Daily Job Leads
                        </Typography>
                        <Box component='img' src={EnvIcon} alt="Mail Icon" sx={{width:100,height:100}} pt={2}/>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem', color:'white', wordSpacing:4}} mt={2} ml={2.5} >
                        Tired of shifting through countless irrelevant job postings? We’ve got you covered! 
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem', color:'white', wordSpacing:4}} mt={2} ml={2.5} mr={2}>
                        Our platform brings you <span style={{color:'#7DF9FF'}}>the freshest job listings</span> from LinkedIn, <span style={{color:'#7DF9FF'}}>carefully tailored to your domain</span> and preferences, all within the last <span style={{color:'#7DF9FF'}}>18 hours</span>. Whether you're a tech wizard, a creative genius, or a management guru, we make sure you’re the <span style={{color:'#7DF9FF'}}>first to know</span> about the most relevant opportunities in your field.
                        </Typography>
                        <Button variant="contained" size="small" sx={{width : "200px", borderRadius : "60px", fontFamily:'SUSE', letterSpacing:5, backgroundColor:'white',color:'black', ':hover' : {backgroundColor:'#4FBAC7', color:'white'}, boxShadow:'20px 20px 30px rgba(0,0,0,0.3)', marginTop:'10px' }} onClick={() => 
                { document.getElementById('enroll').scrollIntoView({behavior:'smooth'});}}>I AM IN!</Button>
                    </Grid2>
                    <Grid2 item xs={12} md={6} sx={{border: '1px solid #4FBAC7',borderRadius:'20px', boxShadow:'5px 5px 15px rgba(0,0,0,0.2)',transition:'box-shadow 0.3s ease-in-out',display:'flex',alignItems:'center',height:{xs:'auto', md:'inherit'},width:'390px', flexDirection:'column', ':hover' :{boxShadow:'10px 10px 20px rgba(0,0,0,0.3)'}}} direction='row'>
                        <Typography variant="h4" style={{fontFamily:'SUSE', fontSize: {xs:'2rem', md:'3rem'}, wordSpacing:5, color:'white'}} mt={2} ml={2}>
                            Tailored JOB Matches
                        </Typography>

                        <Box component='img' src={matchIcon} alt="Mail Icon" sx={{width:100,height:100}} pt={2}/>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem',color:'#D2042D', letterSpacing:10}} mt={2} ml={2.5}>
                           | COMING SOON |
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem',color:'#4FBAC7', letterSpacing:10}} mt={2} ml={2.5}>
                           | COMING SOON |
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem',color:'#D2042D', letterSpacing:10}} mt={2} ml={2.5}>
                           | COMING SOON |
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem',color:'#4FBAC7', letterSpacing:10}} mt={2} ml={2.5}>
                           | COMING SOON |
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem',color:'#D2042D', letterSpacing:10}} mt={2} ml={2.5}>
                           | COMING SOON |
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem',color:'#4FBAC7', letterSpacing:10}} mt={2} ml={2.5}>
                           | COMING SOON |
                        </Typography>
                        <Typography variant="body1" style={{fontFamily:'SUSE',xs:'1rem', md:'2rem',color:'#D2042D', letterSpacing:10}} mt={2} ml={2.5}>
                           | COMING SOON |
                        </Typography>
                        
                    </Grid2>
                    </Grid2>
            </Box>
        </Fragment>
    )
}