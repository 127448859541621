import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Homepage from './home/homepage';
import {Fragment, useEffect, useState} from "react";
import MainAppBar from './menu-bars/main-app-bar';
import NotFound from './not-found/NotFound';
import { Helmet } from "react-helmet";

function App() {
  const [activeSection, setActiveSection] = useState('');
  useEffect(() => {
    const sections = ['benefits', 'services', 'about-us','enroll', 'home'];

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };
    const observerOptions = {
      threshold: 0.6, // Observe when 60% of the section is visible
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    // Observe each section
    sections.forEach((id) => {
      const section = document.getElementById(id);
      if (section) observer.observe(section);
    });

    return () => {
      // Cleanup observer on unmount
      sections.forEach((id) => {
        const section = document.getElementById(id);
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <><MainAppBar activeSection={activeSection} setActiveSection={setActiveSection} /><Homepage /></>
    },
    {
      path:"*",
      element:<><NotFound /></>
    }
    
  ])
  return (
    <Fragment>
       <Helmet>
            {/* <!-- Google tag (gtag.js) -->  */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-NTC7HLEX7Y"></script> 
            <script>{` window.dataLayer = window.dataLayer || []; 
                function gtag(){dataLayer.push(arguments);}
                 gtag('js', new Date()); 
                 gtag('config', 'G-NTC7HLEX7Y');`}
                 </script>
        </Helmet>
      <RouterProvider router={router} />
    </Fragment>
  );
}

export default App;
