import React, { Fragment} from 'react';
import { AppBar,Button, Toolbar,useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from "@mui/system";



// Custom styled AppBar
const CustomAppBar = styled(AppBar)(({ theme }) => ({
  height: '60px', // Adjust the height to make it shorter
  borderRadius: '30px 30px 30px 30px', // Rounded edges
  top: '1%',
  // left: '50%',
  transform: 'translateX(-50%)', 
  width: '50%', // Full width
  display: 'flex',
  justifyContent: 'center',
  // transition : 'top 0.3s',
  backgroundColor:'black',
  // position: 'fixed',
  opacity: '1' // Center content
}));

const CustomToolbar = styled(Toolbar)({
  width: 'auto', // Full width of the AppBar
  display: 'flex',
  justifyContent: 'space-between',
});

const MainAppBar = ({ activeSection, setActiveSection }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if(window.scrollY > 100){
    //             setShowAppBar(true);
    //         }
    //         else{
    //             setShowAppBar(false);
    //         }
    //     };

    //     window.addEventListener('scroll',handleScroll);

    //     return() =>{
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);
  return (
    <Fragment>
     { !isMobile &&
    <CustomAppBar position="fixed" >
      <CustomToolbar>
      {/* <Typography variant="h6" sx={{fontFamily:'SUSE', letterSpacing:5}}>MATCH ME JOBS</Typography> */}
      <Button sx={{letterSpacing:3, color:'#3497A3',}} color={activeSection ==='home'? "#3497A3":"#3497A3"} onClick={() => 
       { document.getElementById('home').scrollIntoView({behavior:'smooth'}); setActiveSection('home')}}><strong>MATCH ME JOBS</strong></Button>
      <Button color={activeSection ==='benefits'?"primary":"inherit"} onClick={() => 
       { document.getElementById('benefits').scrollIntoView({behavior:'smooth'}); setActiveSection('benfits')}
      }>Benefits</Button>
      <Button color={activeSection ==='services'?"primary":"inherit"} onClick={() => 
       { document.getElementById('services').scrollIntoView({behavior:'smooth'}); setActiveSection('services')}
      }>Services</Button>
      <Button color={activeSection ==='enroll'?"primary":"inherit"} onClick={() => 
       { document.getElementById('enroll').scrollIntoView({behavior:'smooth'}); setActiveSection('enroll')}
      }>Subscribe</Button>
      <Button color={activeSection ==='about-us'?"primary":"inherit"} onClick={() => 
       { document.getElementById('about-us').scrollIntoView({behavior:'smooth'}); setActiveSection('about-us')}
      }>About Us</Button>
      </CustomToolbar>
    </CustomAppBar>}
    </Fragment>
  );
};

export default MainAppBar;